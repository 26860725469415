import {
  Box,
  Button,
  createStyles,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { ConfirmationNumber } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { I18n, Translate } from 'react-redux-i18n';
import { FormInputDropzone } from './FormInputDropzone';
import { useDispatch } from 'react-redux';
import message from 'plugins/utils/message';
import { createTicket } from 'store/actions/ticket';
import { obtenerParametricas } from 'repositories/parametrica_repository';
import { Autocomplete } from '@material-ui/lab';

export interface ITicketForm {
  asunto: string;
  descripcion: string;
  files: any;
}

type ParametricaOption = {
  id: string;
  codigo: string;
  grupo: string;
  nombre: string;
  descripcion: string;
};

export type SelectOption = {
  value: string | number;
  label: string;
  caption: string;
};

export const POSITION_DEFAULT = { x: 170, y: 80 };

const ButtonCreateTicket = ({
  user,
}: {
  user: {
    email: string;
    rol: string;
  };
}) => {
  const { handleSubmit, control, trigger, reset, setValue } =
    useForm<ITicketForm>({
      shouldUnregister: false,
      mode: 'all',
    });

  const [isFormOpen, setIsFormOpen] = useState(false);

  const dispatch = useDispatch();

  const handleFabClick = () => {
    if (!isFormOpen) {
      const defaultX = window.innerWidth - POSITION_DEFAULT.x;
      const defaultY = window.innerHeight - POSITION_DEFAULT.y;
      setPosition({ x: defaultX, y: defaultY });
    }

    setIsFormOpen((prev) => !prev);
  };

  const [parametricaTipoProblema, setParametricaTipoProblema] = React.useState<
    SelectOption[]
  >([]);

  const [parametricasPorProblema, setParametricasPorProblema] = React.useState<
    SelectOption[]
  >([]);

  const classes = useStyles();

  const onSubmit = (data: ITicketForm) => {
    trigger();

    if (data.asunto.trim().length === 0) {
      message.error('El asunto es requerido');
      return;
    }

    if (data.descripcion.trim().length === 0) {
      message.error('La descripción es requerida');
      return;
    }
    dispatch(createTicket(data));

    message.success(I18n.t('form.creandoTicket'));

    setIsFormOpen(false);
    reset();
  };

  const [position, setPosition] = useState({
    x: window.innerWidth - POSITION_DEFAULT.x,
    y: window.innerHeight - POSITION_DEFAULT.y,
  });

  const handleDrag = (e: any) => {
    if (isFormOpen) return;
    const newX = e.clientX - e.target.offsetWidth / 2;
    const newY = e.clientY - e.target.offsetHeight / 2;
    setPosition({ x: newX, y: newY });
  };

  useEffect(() => {
    const defaultX = window.innerWidth - POSITION_DEFAULT.x;
    const defaultY = window.innerHeight - POSITION_DEFAULT.y;
    setPosition({ x: defaultX, y: defaultY });
  }, []);

  const cargarOpcionesPorProblema = async (tipoProblema: string) => {
    if (tipoProblema.trim().length === 0) return;
    setValue('descripcion', '');
    const parametricasResponse: any = await obtenerParametricas(tipoProblema);
    const listado = parametricasResponse as ParametricaOption[];
    const problemasTickets: SelectOption[] = (listado || []).map((item) => ({
      value: item.nombre || '',
      label: `${item.nombre}`,
      caption: item.codigo,
    }));
    setParametricasPorProblema(problemasTickets);
  };

  const cargarOpciones = async () => {
    const parametricasResponse: any = await obtenerParametricas(
      'TIPO_PROBLEMA_TICKET',
    );
    const listado = (parametricasResponse as ParametricaOption[]).sort((a, b) =>
      a.id.localeCompare(b.id),
    );
    const problemasTickets: SelectOption[] = (listado || []).map((item) => ({
      value: item.nombre || '',
      label: `${item.nombre}`,
      caption: item.codigo,
    }));
    setParametricaTipoProblema(problemasTickets);
  };
  const cargarOpcionesCallback = React.useCallback(cargarOpciones, []);

  React.useEffect(() => {
    if (isFormOpen) cargarOpcionesCallback();
  }, [cargarOpcionesCallback, isFormOpen]);

  useEffect(() => {
    setIsFormOpen(false);
    reset();
    setParametricasPorProblema([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('locale')]);

  return (
    <div
      style={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        cursor: 'move',
        zIndex: 50,
      }}
      draggable
      onDragEnd={handleDrag}
    >
      {user.rol === 'PROFESOR' && (
        <>
          <Fab
            variant="extended"
            className={classes.fabButton}
            size="small"
            color="primary"
            onClick={handleFabClick}
          >
            <ConfirmationNumber style={{ marginLeft: 'auto' }} />
            <span
              style={{
                flexGrow: 1,
                textAlign: 'center',
                textTransform: 'none',
              }}
            >
              <Translate value="crearTicket" />
            </span>
          </Fab>

          {isFormOpen && (
            <Box component="form" className={classes.formContainer}>
              <Box style={{ gap: '10px' }}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: 'bold', textAlign: 'center' }}
                >
                  <Translate value="crearTicket" />
                </Typography>

                <Typography
                  variant="body2"
                  style={{
                    marginTop: '10px',
                    textAlign: 'justify',
                  }}
                >
                  <Translate value="infoCrearTicket" />
                </Typography>
              </Box>

              <Grid
                container
                style={{
                  marginTop: '10px',
                }}
                spacing={2}
              >
                <Grid item xs={12} md={12}>
                  <Controller
                    name="asunto"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        variant="filled"
                        required
                        className={classes.formControl}
                      >
                        <InputLabel>{I18n.t('form.motivoTicket')}</InputLabel>
                        <Select
                          {...field}
                          onChange={(e) => {
                            const valorSeleccionado = e.target.value as string;

                            const parametrica = parametricaTipoProblema.find(
                              (option) => option.value === valorSeleccionado,
                            );

                            if (parametrica) {
                              field.onChange(e);
                              cargarOpcionesPorProblema(
                                parametrica.caption || '',
                              );
                            } else {
                              console.error(
                                'El valor seleccionado no es válido',
                              );
                            }
                          }}
                        >
                          {parametricaTipoProblema.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Controller
                    name="descripcion"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        variant="filled"
                        required
                        disabled={parametricasPorProblema.length === 0}
                        className={classes.formControl}
                      >
                        <Autocomplete
                          options={parametricasPorProblema}
                          autoHighlight
                          getOptionLabel={(option: SelectOption) =>
                            option.label
                          }
                          renderOption={(option: SelectOption, state) => (
                            <Box {...state}>
                              <Typography variant="body2">
                                {option.label}
                              </Typography>
                            </Box>
                          )}
                          value={
                            parametricasPorProblema.find(
                              (option) => option.value === field.value,
                            ) || null
                          }
                          onChange={(_event, newValue: SelectOption | null) => {
                            field.onChange(newValue ? newValue.value : '');
                          }}
                          disabled={parametricasPorProblema.length === 0}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={I18n.t('form.descripcionTicket')}
                              variant="filled"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Controller
                    name="files"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormInputDropzone
                        label={I18n.t('form.subirArchivos')}
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        acceptedFiles={['image/*', 'application/pdf']}
                        error={!!fieldState.error}
                        maxFileSize={10 * 1024 * 1024}
                        dropzoneText={I18n.t('form.dropzoneTextFiles')}
                        helperText={
                          fieldState.error
                            ? 'Please upload valid files'
                            : undefined
                        }
                      />
                    )}
                  />
                </Grid>

                <Button
                  style={{
                    marginLeft: 'auto',
                    marginTop: '10px',
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  <Translate value="enviarTicket" />
                </Button>
              </Grid>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fabButton: {
      gap: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '140px !important',
    },
    formContainer: {
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'fixed',
      bottom: '100px',
      right: '20px',
      zIndex: 10,
      backgroundColor: 'white',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      border: '1px solid rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '400px',
      maxHeight: 'calc(100vh - 150px)',
      gap: '10px',
    },
    formControl: {
      width: '100%',
    },
  }),
);

export default ButtonCreateTicket;
