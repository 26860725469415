import swal from "sweetalert";
import { I18n } from "react-redux-i18n";

const Message = {
  success(text: string, timer?: number) {
    swal({
      title: I18n.t("mensaje.correcto"),
      text,
      icon: "success",
      buttons: {
        confirm: false,
      },
      timer: timer || 3000,
    });
  },
  error(text: string, timer?: number) {
    swal({
      title: I18n.t("mensaje.error"),
      text,
      icon: "error",
      buttons: {
        confirm: false,
      },
      timer: timer || 3000,
    });
  },
  warning(text: string, timer?: number) {
    swal({
      title: I18n.t("mensaje.advertencia"),
      text,
      icon: "warning",
      buttons: {
        confirm: false,
      },
      timer: timer || 3000,
    });
  },
  alert() {},
  async confirm(text: string) {
    const deleted = await swal({
      title: I18n.t("mensaje.confirmar"),
      text,
      icon: "warning",
      dangerMode: true,
      buttons: [I18n.t("form.cancelar"), I18n.t("form.aceptar")],
    });

    return deleted;
  },
};

export default Message;
