import { ITicket } from 'models/ticket_model';
import {
  createTicketService,
  getTicketMonitorService,
  getTicketProfesorService,
} from 'repositories/ticket_repository';
import { TICKET_TYPES } from 'store/types';
import { ITicketForm } from 'views/Ticket/components/form-ticket';

export const createTicket = (data: ITicketForm) => {
  return async (dispatch: any) => {
    await createTicketService(data);
  };
};

export const cargarTicketsProfesor = () => {
  return async (dispatch: any) => {
    const { rows } = await getTicketProfesorService();
    dispatch(setTickets(rows || []));
  };
};

export const cargarTicketsMonitor = () => {
  return async (dispatch: any) => {
    const { rows } = await getTicketMonitorService();
    dispatch(setTickets(rows || []));
  };
};

export const setTickets = (tickets: ITicket[]) => ({
  type: TICKET_TYPES.LOAD_TICKETS,
  payload: [...tickets],
});
